// allocation Routes...
const farmAllocation = '/farmMachinery/allocation/farm-allocation/'
export const farmAllocationStore = farmAllocation + 'store'
export const farmAllocationList = farmAllocation + 'list'
export const farmAllocationApprovedList = farmAllocation + 'approved-list'
export const farmAllocationUpdate = farmAllocation + 'update'
export const existDistrictAllocationApi = farmAllocation + 'exist-district-allocation'
export const farmAllocationMultipleApprovedApi = farmAllocation + 'allocation-approve'

// circular list api
export const circularListApi = '/farmMachinery/allocation/circular-list'
// machine type list api
export const machineTypeListApi = '/farmMachinery/allocation/machine-type-list'
// distribution
export const farmDistributionCircularListApi = '/farmMachinery/allocation/distribution-circular-list'
export const appVerifyFarmDistributionCircularListApi = '/farmMachinery/allocation/app-verify-circular-list'
export const allocationDistributionDetailsApi = '/farmMachinery/allocation/distribution-details-circular-wise'
export const selectedFarmerListApi = '/farmMachinery/allocation/selected-farmer-list'
export const approvedFarmerListApi = '/farmMachinery/allocation/approved-farmer-list'
export const farmerApplicationWidthdrawApi = '/farmMachinery/allocation/farmer-application-widthdraw'
export const distributionAssignApi = '/farmMachinery/allocation/distribution-assign-store'
export const distributionAssignSupplierListApi = '/farmMachinery/allocation/distribution-assign-supplier-list'
export const distributionSupplierDropdownListApi = '/farmMachinery/allocation/distribution-dropdown-list'
