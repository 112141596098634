
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('farm_config.pending_distribution') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <!-- fiscal year id -->
                  <b-col lg="6" sm="12">
                      <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fiscal_year_id"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                  {{$t('globalTrans.fiscal_year')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.fiscal_year_id"
                                  id="fiscal_year_id"
                                  :options="fiscalYearList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <!-- season -->
                  <b-col lg="6" sm="12">
                      <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="season_id"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                  {{$t('farm_config.season')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.season_id"
                                  id="season_id"
                                  :options="seasonList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('farm_config.pending_distribution') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <!-- <template v-slot:headerAction>
                      <router-link class="btn btn-sm btn-primary" :to="{name: 'incentive_grant_service.farm_machinery.allocation.allocation_entry'}" variant="primary btn_add_new">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </router-link>
                    </template> -->
                    <template v-slot:body>
                      <b-overlay :show="loadingState">
                        <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table  :emptyText="$t('globalTrans.noDataFound')" show-empty thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                  <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                  </template>
                                  <template v-slot:cell(fiscal_year_id)="data">
                                    {{ getFiscalYearName(data.item.fiscal_year_id)}}
                                  </template>
                                  <template v-slot:cell(season_id)="data">
                                    {{ getSeasonName(data.item.season_id)}}
                                  </template>
                                  <template v-slot:cell(circular_id)="data">
                                    {{ currentLocale === 'en' ? data.item.circular_name : data.item.circular_name_bn}}
                                  </template>
                                  <template v-slot:cell(total_farmer)="data">
                                    {{ $n(data.item.applications_count) }}
                                  </template>
                                  <template v-slot:cell(action)="data">
                                    <router-link :to="{ name: 'incentive_grant_service.dae.subsidy.verification_approval', query: {fiscal_year_id: data.item.fiscal_year_id, circular_id: data.item.id } }">
                                        <i class="fas fa-eye"></i>
                                    </router-link>
                                  </template>
                                </b-table>
                                <b-pagination
                                    align="center"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                    />
                            </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { appVerifyFarmDistributionCircularListApi, farmMachineryToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/route-base-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        season_id: 0,
        circular_id: 0,
        upazilla_id: 0
      },
      circularList: [],
      rows: [],
      item: ''
    }
  },
  mounted () {
    if (this.authUser.role_id !== 1 && this.authUser.is_org_admin === 0) {
        if (this.authUser.office_detail.upazilla_id) {
            this.search.upazilla_id = this.authUser.office_detail.upazilla_id
        }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('farm_config.supplier_info') + ' ' + this.$t('globalTrans.add') : this.$t('farm_config.supplier_info') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
          { label: this.$t('farm_config.season'), class: 'text-center' },
          { label: this.$t('farm_config.circular'), class: 'text-center' },
          { label: this.$t('globalTrans.total_farmer'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_id' },
          { key: 'season_id' },
          { key: 'circular_id' },
          { key: 'total_farmer' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_id' },
          { key: 'season_id' },
          { key: 'circular_id' },
          { key: 'total_farmer' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    seasonList: function () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
      ]
    },
    fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    districtList: function () {
      return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    }
  },
  watch: {
      loadingState: function (newVal, oldVal) {
        if (newVal) {
          this.loadData()
        }
      },
      currentLocale: function (newVal, oldVal) {
      }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, farmMachineryToggleStatus, item, 'incentive_grant')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, appVerifyFarmDistributionCircularListApi, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getSeasonName (id) {
      const obj = this.seasonList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getDistrictName (id) {
      const obj = this.$store.state.commonObj.districtList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
